<template>
    <div>        
    <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">新闻中心</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../assets/banner/banner5.png'>
    </section>
    <section class="am-path">
        <div class="am-container">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/Journalism' }">新闻中心</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
    </section>
    <section class="nybox">
        <div class="am-container1">
            <div class="nyleft1">
                <el-col>
                <p class="tit">新闻中心</p>
                <ul class="menu">
                    <li v-for='(item,index) in dataList' :key='index' ><a @click="func(item)" href="javascript:;"> {{item.article_name}}</a></li>
                </ul>
  </el-col> 
            </div>
            <div class="nyright">
                <div class="nyproxq">
                    <div class="nyprohead">
                        <h1>{{this.Data.article_name}}</h1>
                    </div>
                    <div class="markdown-box">
                        <p v-html="this.Data.article_details"></p>
                    </div>
                    </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import {
    getListxw,
    getOnexw
} from 'api/xw'
export default {
    data() {
        return {
            searchInfo:{
                page_no: 1,
                page_size: 8,
                order_by:'add_time desc'
            },
            imagesbox:[
                {id:0,idView:require('../assets/images/501.jpg')},
                {id:1,idView:require('../assets/images/502.jpg')},
                {id:2,idView:require('../assets/images/503.jpg')}
            ],
            Data:[

            ],
            dataList:[],
            tableDate:[
                {
                    id:'1',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'2',
                    xtlb:'',
                    gn:'温湿度监测',
                    gnxq:'实时监测动力环境内各采样点的温度和湿度参数。'
                },
                {
                    id:'3',
                    xtlb:'',
                    gn:'精密空调监测',
                    gnxq:'监测精密空调回风温度、送风温度、空调模式、运行状态、设定参数以及告警故障等信息。'
                },
                {
                    id:'4',
                    xtlb:'',
                    gn:'普通空调监测',
                    gnxq:'监测空调开关机状态及环境温湿度信息，支持红外远程开关机、模式选择、温度调节、风速调节等操作。'
                },
                {
                    id:'5',
                    xtlb:'',
                    gn:'新风机监测',
                    gnxq:'监测新风机开关机及运行状态。'
                },
                {
                    id:'6',
                    xtlb:'动力系统',
                    gn:'配电监测',
                    gnxq:'实时监测动力环境的供电质量，包含供电电压、电流、功率因数、频率、有功功率、无功功率、视在功率、电能等。'
                },
                {
                    id:'7',
                    xtlb:'环境系统',
                    gn:'漏水监测',
                    gnxq:'对空调、加湿器、门口、窗户等附近进行围闭监测，有水漏出时可实时报警。'
                },
                {
                    id:'8',
                    xtlb:'',
                    gn:'UPS监测',
                    gnxq:'监测输入输出电压、电流、功率、后备时间等参数，整流器、逆变器、电池、旁路等部件运行状态和告警信息。'
                },
                {
                    id:'9',
                    xtlb:'',
                    gn:'市电监测',
                    gnxq:'监测单相或三相四线ABC相中任一相断电状态'
                },
                {
                    id:'10',
                    xtlb:'网络系统（注：暂不支持，开发中）',
                    gn:'路由器',
                    gnxq:'TCP服务端口网络状态'
                }
            ],
        }
    },
    methods: {
    getList() {
        this.isShowloading = true;
      getListxw(this.searchInfo).then((res) => {
        this.dataList = res.data.list;
        this.isShowloading = false;
      });
      },
    getOne() {
        let id = this.$route.query.id;
        getOnexw({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
    },
    func(item) {
         let id = item.fuid;
        getOnexw({fuid: id}).then((res) => {
            this.Data = res.data.entity
        })
      }
    },
    mounted() {
        this.getList(),
        this.getOne()
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.am-container1 {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.am-container1::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    // border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}
}
.nybox {
    padding: 10px 0;
    background-color: #f5f5f5;
    .nyleft1 {
        position: relative;
        float: left;
        width: 20%;
        overflow: hidden;
        .tit{
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }
        .menu {
            background-color: #fff;
            padding: 6px 10px;
            text-align: center;
            li{
                margin-top:5px ;
width:100%;
height:50px;
overflow:hidden;
text-overflow:ellipsis;
display:-webkit-box;
-webkit-line-clamp:2;
-webkit-box-orient:vertical;
                border-bottom: 1px #4e3b3b2f solid;
                a{
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
    .nyright {
        width: 78%;
        float: right;
        background-color: #fff;
        .nyproxq {
            height: 100%;
        }
        .nyproxq::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }        
        .nyprohead::after{
               content: ".";
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
                -webkit-box-sizing: border-box;
                 box-sizing: border-box; 
        }
        .nyprohead {
            padding: 30px;
            display: block;
            height: 100%;
            h1{
                font-size:22px ;
                font-weight: normal;
            }
        }
        .xgcp {
                padding: 15px 0;
                margin: 0 30px;
                font-size: 22px;
                border-bottom: 1px solid #eee;
                text-align: left;
        }
        .markdown-box{
            text-align: left;
            text-indent:0 !important;
            overflow: hidden;
            padding: 10px;
        }
            .markdown-box ::v-deep * {
            word-break: break-word;
            max-width: 100% !important;
            width: auto !important;
            }
            .markdown-box ::v-deep img {
            width: auto !important;
            height: auto !important;
            max-width: 100% !important;
            max-height: 100% !important;
            // 让img水平居中：
            clear: both;
            display: block;
            margin: auto;
            }
        .xgproli {
            padding: 15px;
                 ul {
                    display: -webkit-flex;
                    display: flex;
                    align-content: space-around;
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-align-content: center;
                    align-content: center;
                    justify-content: space-between;
                    li {
                    padding:0 10px ;
                    list-style: none;
                    width: 30%;
                    a{
                        display: block;
                        background: #fff;
                        text-align: center;
                        border: 1px solid #fff;
                        overflow: hidden;
                        i {
                            overflow: hidden;
                        }
                        img {
                            width: 100%;
                            transition: all 0.7s;
                        }
                        img:hover {
                            transform: scale(1.2) ;
                        }
                        .txt{
                            padding:20px;
                        .tit {
                                font-size: 16px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-bottom: 4px;
                                color: #333;
                        }
                        .note {
                                font-size: 12px;
                                margin-bottom: 6px;
                                color: #999;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                        }}
                    }
                    a:hover {
                        border: 1px solid #0e90d2
                    }
                }
                        }
                }
    }
}
.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}
</style>
<style>
.nyleft1 .el-submenu__title {
  display: flex;
  align-items: center;
}
.nyleft1 .el-submenu__title span{
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  flex: 1;
  padding-right: 20px;
}
 
.nyleft1 .el-menu-item {
  display: flex;
  align-items: center;
  padding-right: 20px!important;
}
.nyleft1 .el-menu-item span {
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  flex: 1;
}
</style>